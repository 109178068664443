var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{on:{"click":function($event){_vm.visible = true}}},[_vm._t("default")],2),_c('a-modal',{attrs:{"title":"关联项目","visible":_vm.visible,"footer":null,"width":"800px"},on:{"cancel":_vm.cancel}},[_c('a-input-search',{attrs:{"placeholder":"请输入关键词搜索","enter-button":""},on:{"search":_vm.onSearch}}),_c('Padding'),_c('a-table',{attrs:{"bordered":"","data-source":_vm.list,"loading":_vm.loading,"pagination":{
        total: _vm.total,
        current: _vm.current,
        pageSize: _vm.pageSize,
        showTotal: (total) => `共 ${total} 条记录`,
      },"rowClassName":() => 'clickable',"customRow":(record) => {
          return {
            on: {
              click: () => {
                _vm.onTableRowClick(record);
              },
            },
          };
        }},on:{"change":_vm.onChange}},[_c('a-table-column',{attrs:{"title":"设计编号","data-index":"designCode"}}),_c('a-table-column',{attrs:{"title":"项目名称","data-index":"name"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }