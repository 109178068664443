<template>
  <div>
    <Pane>
      <template v-slot:extra>
        <Draft @select="onSelectDraft" />
      </template>
    </Pane>

    <a-modal
      :visible="draftVisible"
      title="保存草稿"
      @ok="saveDraft"
      @cancel="cancelDraft"
    >
      <div style="padding: 8px 0">
        <a-input placeholder="请输入草稿标题" v-model="draftTitle" />
      </div>
    </a-modal>

    <a-form
      :form="form"
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 12 }"
      :colon="false"
      @submit="handleSubmit"
    >
      <div class="container">
        <div class="header">
          <img src="../../assets/contract-register/icon-info.png" alt="" />
          <span>基本信息</span>
        </div>
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="经办部门">
              <a-select
                placeholder=""
                :disabled="true"
                :filter-option="$selectFilterOption"
                style="width: 100%"
                v-decorator="[
                  'operationDeptId',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
              >
                <a-select-option
                  v-for="item in organizationList"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.uniqueName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item>
              <span class="required" slot="label">经办人</span>

              <a-button block style="text-align: left" :disabled="true">
                {{ selectedUser.name }}
              </a-button>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item>
              <span class="required" slot="label">关联投标及项目委托</span>
              <InfoSelector @change="onSelectInfo">
                <a-button
                  class="ellipsis"
                  style="margin-top: 4px; text-align: left"
                  block
                >
                  {{ selectedInfo.projectName }}
                </a-button>
              </InfoSelector>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item>
              <span class="required" slot="label">合同编号</span>
              <ProjectSelector @change="onSelectProject">
                <a-button style="margin-top: 4px; text-align: left" block>
                  {{ newDesignCode }}
                </a-button>
              </ProjectSelector>
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="合同名称">
              <a-input
                v-decorator="[
                  'name',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="合同金额(万元)">
              <a-input-number
                :formatter="$inputNumberFormat"
                v-decorator="[
                  'contractAmount',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="关联合同">
              <ContractSelector
                @change="onSelectContract"
                @cancel="cancelSelectContract"
              >
                <a-button style="text-align: left" class="text-hidden" block>
                  <span v-if="selectedContract.name">{{
                    selectedContract.name
                  }}</span>
                  <span v-else>&nbsp;</span>
                </a-button>
              </ContractSelector>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item>
              <span class="required" slot="label">业主方</span>
              <CompanySelector
                @change="(company) => (selectedCompany = company)"
              >
                <a-button
                  block
                  style="margin-top: 4px; text-align: left"
                  class="ellipsis"
                >
                  {{ selectedCompany.name }}
                </a-button>
              </CompanySelector>
            </a-form-item>
          </a-col>
          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="用章名称"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 16 }"
            >
              <a-checkbox-group
                v-decorator="[
                  'sealType',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
              >
                <a-checkbox
                  v-for="item in sealTypeList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.name }}
                </a-checkbox>
              </a-checkbox-group>
            </a-form-item>
          </a-col>

          <a-col
            :lg="24"
            :md="24"
            :sm="24"
            v-if="selectedInfo.projectName === '无'"
          >
            <a-form-item
              label="说明"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-textarea
                v-decorator="[
                  'bidApprovalInstruction',
                  {
                    initialValue: detail.bidApprovalInstruction,
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
                :auto-size="{ minRows: 3 }"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </div>

      <div class="container">
        <div class="header">
          <img src="../../assets/contract-register/icon-project.png" alt="" />
          <span>项目信息</span>
        </div>
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="责任部门">
              <a-select
                show-search
                :filter-option="$selectFilterOption"
                v-decorator="[
                  'productionUnitId',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
                style="width: 100%"
                placeholder=""
              >
                <a-select-option
                  v-for="item in productionDepartmentList"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.uniqueName }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item>
              <span class="required" slot="label">项目负责人</span>
              <EmployeeSelector
                @change="
                  (arr) => {
                    if (arr.length > 0) {
                      selectedCharger = arr[0];
                    } else {
                      selectedCharger = {};
                    }
                  }
                "
              >
                <a-button block style="text-align: left">
                  <div v-if="selectedCharger.name">
                    {{ selectedCharger.name }}
                  </div>
                  <div v-else>&nbsp;</div>
                </a-button>
              </EmployeeSelector>
              <div class="tip">
                <a-icon type="question-circle" />
                请按照招标文件及合同中人员填写
              </div>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="所属区域">
              <a-cascader
                placeholder=""
                v-decorator="[
                  'area',
                ]"
                :options="areaList"
                :fieldNames="{
                  label: 'areaName',
                  value: 'key',
                  children: 'children',
                }"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="总投资(万元)">
              <a-input-number
                :formatter="$inputNumberFormat"
                v-decorator="[
                  'investment',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="项目地点">
              <a-cascader
                placeholder=""
                v-decorator="[
                  'location',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
                :options="cityList"
                :fieldNames="{
                  label: 'name',
                  value: 'name',
                  children: 'children',
                }"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="国外">
              <a-input v-decorator="['isAbroad']" />
              <div class="tip">
                <a-icon type="question-circle" />
                项目地点在国外时填写
              </div>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item>
              <span class="required" slot="label">合同性质</span>

              <ContractPropertySelector
                :selectedProperty="selectedProperty"
                @change="(payload) => (selectedProperty = payload)"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="联合体单位"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-textarea
                v-decorator="[
                  'unionType',
                  {
                    rules: [
                      {
                        required:
                          selectedProperty.value === 'epc-qt' ||
                          selectedProperty.value === 'epc-cyf',
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
                :auto-size="{ minRows: 3 }"
              />
              <div class="tip">
                <a-icon type="question-circle" />
                注明在联合体中的地位
              </div>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="是否集团项目">
              <a-radio-group
                v-decorator="[
                  'isGroup',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
              >
                <a-radio :value="1">是</a-radio>
                <a-radio :value="0">否</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="服务内容"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-checkbox-group
                v-decorator="[
                  'phase',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
              >
                <a-checkbox
                  v-for="item in phaseList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.name }}
                </a-checkbox>
              </a-checkbox-group>
            </a-form-item>
          </a-col>
        </a-row>
      </div>

      <div class="container">
        <div class="header">
          <img src="../../assets/contract-register/icon-scale.png" alt="" />
          <span>专业规模</span>
          <div class="extra">
            <a-button type="primary" size="small" @click="scaleList.push({})"
              >添加</a-button
            >
          </div>
        </div>

        <Padding size="large" />

        <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
          <span class="required" slot="label">专业规模</span>
          <div style="margin-top: 4px">
            <ScaleList
              :list="scaleList"
              @remove="(index) => scaleList.splice(index, 1)"
              @setList="(list) => (scaleList = list)"
            />
          </div>
        </a-form-item>

        <a-form-item
          label="工程概况"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
        >
          <a-textarea
            placeholder="请输入工程概况"
            :auto-size="{ minRows: 3 }"
            v-decorator="[
              'scale',
              { rules: [{ required: true, message: '请输入！' }] },
            ]"
          />
        </a-form-item>

        <Padding size="large" />
      </div>

      <div class="container">
        <div class="header">
          <img
            src="../../assets/contract-register/icon-collection.png"
            alt=""
          />
          <span>合同收款项</span>
          <div class="extra">
            <a-button type="primary" size="small" @click="addCollectionList"
              >添加</a-button
            >
          </div>
        </div>

        <Padding size="large" />

        <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
          <span class="required" slot="label">合同收款项</span>
          <div style="margin-top: 4px">
            <CollectionList
              :money="form.getFieldValue('contractAmount')"
              :list="collectionList"
              @remove="(index) => collectionList.splice(index, 1)"
            />
          </div>
        </a-form-item>

        <Padding size="large" />
      </div>

      <div class="container">
        <div class="header">
          <img src="../../assets/contract-register/icon-file.png" alt="" />
          <span>附件</span>
          <div class="extra">
            <FileUpload @uploaded="uploaded">
              <a-button type="primary" size="small">上传</a-button>
            </FileUpload>
          </div>
        </div>

        <Padding size="large" />

        <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
          <span class="required" slot="label">附件</span>
          <div style="margin-top: 4px">
            <a-table bordered :dataSource="fileList" :pagination="false">
              <a-table-column title="附件名称">
                <template slot-scope="text">
                  <a :href="text.completePath" target="_blank">
                    {{ $getFileName(text.completePath) }}
                  </a>
                </template>
              </a-table-column>
              <a-table-column title="操作" align="center" width="120px">
                <template slot-scope="text, record, index">
                  <a href="#" class="danger" @click.prevent="removeFile(index)"
                    >删除</a
                  >
                </template>
              </a-table-column>
            </a-table>
          </div>
        </a-form-item>

        <Padding size="large" />
      </div>

      <div class="center">
        <a-space>
          <a-button @click="addDraft">存草稿</a-button>
          <a-button type="primary" htmlType="submit" :loading="saveLoading">
            {{ id ? "重新提交审批" : "提交审批" }}
          </a-button>
          <a-button @click="$close($route.path)">关闭</a-button>
        </a-space>
      </div>
    </a-form>

    <ExtraInfo />

    <CommentModal
      :visible="commentVisible"
      :title="commentTitle"
      @setVisible="setCommentVisible"
      @done="done"
    />
  </div>
</template>

<script>
import area from "@/mixins/area";
import organization from "@/mixins/organization";
import watermark from "@/mixins/watermark";
import city from "@/mixins/city";
import draft from "@/mixins/draft";

import { add, fetchCode, fetchDetail, edit } from "@/api/contract-register";
import { mapGetters, mapState } from "vuex";

import InfoSelector from "./components/info-selector";
import ProjectSelector from "./components/project-selector";
import ContractSelector from "@/components/contract-selector";
import ScaleList from "./components/scale-list";
import CollectionList from "./components/collection-list";
import ExtraInfo from "./components/extra-info";

import EmployeeSelector from "@/components/employee-selector";
import CompanySelector from "@/components/company-selector";
import FileUpload from "@/components/file-upload";
import CommentModal from "@/components/comment-modal";

import ContractPropertySelector from "./components/contract-property-selector";
import moment from "moment";

export default {
  name: "addContractRegister",
  mixins: [area, organization, watermark, city, draft],

  components: {
    InfoSelector,
    ProjectSelector,
    ScaleList,
    CollectionList,
    ExtraInfo,
    EmployeeSelector,
    CompanySelector,
    FileUpload,
    CommentModal,
    ContractPropertySelector,

    ContractSelector,
  },

  data() {
    return {
      form: this.$form.createForm(this),
      selectedUser: {}, // 选择的经办人
      selectedInfo: {}, // 关联投标及项目委托
      selectedProject: {}, // 选择的项目，用来生成合同编号
      selectedContract: {},

      newDesignCode: "", // 后端生成的设计编号
      selectedCompany: {}, // 选择的业主方

      selectedCharger: {}, // 选择的项目负责人

      selectedProperty: {}, // 合同性质

      scaleList: [{}], // 规模列表
      collectionList: [{}, {}, {}], // 收款项列表
      fileList: [], // 附件列表

      saveLoading: false,
      id: null, // 重新提交时的 ID
      detail: {}, // 重新提交时获取到的数据

      commentVisible: false, // 审批意见弹窗
      commentTitle: "", // 标题
      storageValue: {}, // 暂存的数据
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    sealTypeList() {
      return this.findDataDict("sealType");
    },
    contractCategoryList() {
      return this.findDataDict("contractCategory");
    },
    phaseList() {
      return this.findDataDict("phase");
    },

    ...mapState("auth", ["user"]),
    ...mapGetters("auth", ["department"]),
  },

  activated() {
    const { query } = this.$route;
    const { id } = query || {};

    if (id && this.id !== id) {
      this.id = id;

      fetchDetail({ id }).then((res) => {
        console.log("detail", res);
        if (res) {
          this.detail = res;
          // 经办
          this.selectedUser = {
            userId: res.operatorId,
            name: res.operatorName,
          };

          // 关联投标及项目委托
          this.selectedInfo = {
            id: res.bidApprovalPid,
            projectName: res.bidApprovalPname,
          };

          // 合同
          this.newDesignCode = res.code;
          this.selectedProject = {
            code: res.projectCode,
            id: res.prjSetupId,
            name: res.prjSetupName,
            designCode: res.prjSetupCode,
          };

          this.selectedContract = {
            id: res.relationContractId,
            name: res.relationContractName,
          };

          // 业务方
          this.selectedCompany.id = res.partyAid;
          this.selectedCompany.name = res.partyAname;

          // 项目负责人
          // this.selectedCharger.userId = res.chargerId;
          // this.selectedCharger.name = res.chargerName;

          this.selectedCharger = {
            userId: res.chargerId,
            name: res.chargerName,
          }

          const area = [];
          if (res.areaId) {
            area.push(res.areaId);
          }
          if (res.subAreaId) {
            area.push(res.subAreaId);
          }
          if (res.l3AreaId) {
            area.push(res.l3AreaId);
          }

          // 项目地点
          const location = [];
          if (res.belongProvince) {
            location.push(res.belongProvince);
          }
          if (res.belongCity) {
            location.push(res.belongCity);
          }
          if (res.district) {
            location.push(res.district);
          }

          for (let i = 0; i < this.contractCategoryList.length; i++) {
            const item = this.contractCategoryList[i];
            const obj = item.children?.find(
              (element) => element.value === res.property
            );
            if (obj) {
              this.selectedProperty = obj;
            }
          }

          this.form.setFieldsValue({
            // 经办
            operationDeptId: res.operationDeptId,

            // 合同
            name: res.name,
            contractAmount: res.contractAmount / 10000,

            // 用章名称
            sealType: res.sealType?.split(","),

            bidApprovalInstruction: res.bidApprovalInstruction, // 项目委托说明

            // 生产部门
            productionUnitId: res.productionUnitId,

            // 所属区域
            area,

            // 是否集团项目
            isGroup: res.isGroup,
            // 联合体情况
            unionType: res.unionType,
            // 项目地点
            location,

            // 国外
            isAbroad: res.isAbroad,
            // 总投资
            investment: res.investment / 10000,
            // 服务内容
            phase: res.phase?.split(","),

            // 工程概况
            scale: res.scale,
          });

          this.scaleList = Array.isArray(res.contractScaleList)
            ? res.contractScaleList
            : [];
          this.collectionList = Array.isArray(res.planReceiptList)
            ? res.planReceiptList
            : [];
          this.fileList =
            typeof res.attachments === "string"
              ? res.attachments.split(",").map((item) => {
                  return {
                    completePath: item,
                  };
                })
              : [];
        }
      });

      return;
    }

    if (!id) {
      if (!this.selectedUser.name) {
        this.selectedUser = {
          userId: this.user.uuid,
          name: this.user.userName,
        };

        this.form.setFieldsValue({
          operationDeptId: this.department.deptId,
        });
      }
    }
  },

  methods: {
    // 选择项目委托
    onSelectInfo(info) {
      this.selectedInfo = info;
      // 项目负责人 就是选择的项目委托的设计负责人
      // this.selectedCharger.userId = info.designEpcLeader;
      // this.selectedCharger.name = info.designEpcLeaderName;
      // fixed reset selected user bug
      this.selectedCharger = {
        userId: info.designEpcLeader,
        name: info.designEpcLeaderName
      }
    },
    // 选择合同
    onSelectProject(value) {
      console.log("value", value);
      this.selectedProject = value;

      fetchCode({ code: value.designCode }).then((res) => {
        if (res) {
          this.newDesignCode = res;
        }
      });

      this.form.setFieldsValue({
        productionUnitId: value.productionUnitId,
      });
    },
    onSelectContract(obj) {
      console.log("obj", obj);
      this.selectedContract = obj;
    },
    cancelSelectContract() {
      this.selectedContract = {};
    },

    // 上传附件
    uploaded(list) {
      this.fileList = [...this.fileList, ...list];
    },
    removeFile(index) {
      this.fileList.splice(index, 1);
    },

    addCollectionList() {
      this.collectionList.push({});
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log(values);

          if (!this.selectedInfo.id) {
            this.$message.error("请关联投标及项目委托！");
            return;
          }
          if (!this.newDesignCode) {
            this.$message.error("请关联项目！");
            return;
          }

          if (!this.selectedCompany.id) {
            this.$message.error("请选择业主方！");
            return;
          }

          if (!this.selectedCharger.userId) {
            this.$message.error("请选择项目负责人！");
            return;
          }

          if (!this.selectedProperty.value) {
            this.$message.error("请选择合同性质！");
            return;
          }

          let flag = false;
          this.scaleList.forEach((item) => {
            if (!item.major || !item.majorSub || !item.scale) {
              flag = true;
            }
          });
          if (flag || !this.scaleList.length) {
            this.$message.error("请填写专业规模！");
            return;
          }
          this.collectionList.forEach((item) => {
            if (!item.name || !item.ratio) {
              flag = true;
            }
          });
          if (flag) {
            this.$message.error("请填写合同收款项！");
            return;
          }

          if (!this.fileList.length) {
            this.$message.error("请上传附件！");
            return;
          }

          let ratio = 0;
          this.collectionList.forEach((item) => {
            ratio += parseFloat(item.ratio);
          });
          if (Math.abs(100 - ratio) > 0.000001) {
            this.$message.error("合同收款项比例之和不等于100%！");
            return;
          }

          let area1 = {};
          let area2 = {};
          let area3 = {};

          if (Array.isArray(values.area)) {
            const [areaId1, areaId2, areaId3] = values.area;
            area1 = this.rawAreaList.find((item) => item.id === areaId1);
            area2 = this.rawAreaList.find((item) => item.id === areaId2);
            area3 = this.rawAreaList.find((item) => item.id === areaId3);
          }

          // 服务内容排序
          const phaseList = [];
          this.phaseList.forEach((item) => {
            const element = values.phase.find(
              (element) => element === item.value
            );
            if (element) {
              phaseList.push(element);
            }
          });

          const params = {
            // 经办
            operationDeptId: values.operationDeptId,
            operationDeptName: this.organizationList.find(
              (item) => item.id === values.operationDeptId
            )?.uniqueName,
            operatorId: this.selectedUser.userId,
            operatorName: this.selectedUser.name,

            // 关联投标及项目委托
            bidApprovalPid: this.selectedInfo.id,
            bidApprovalPname: this.selectedInfo.projectName,
            bidApprovalInstruction: values.bidApprovalInstruction,

            // 合同
            code: this.newDesignCode,
            projectCode: this.selectedProject.code,

            prjSetupId: this.selectedProject.id,
            prjSetupName: this.selectedProject.name,
            prjSetupCode: this.selectedProject.designCode,

            name: values.name,
            contractAmount: values.contractAmount * 10000,

            // 业务方
            partyAid: this.selectedCompany.id,
            partyAname: this.selectedCompany.name,
            // 用章名称
            sealType: values.sealType.join(),

            // 生产部门
            productionUnitId: values.productionUnitId,
            productionUnitName: this.productionDepartmentList.find(
              (item) => item.id === values.productionUnitId
            )?.uniqueName,

            // 项目负责人
            chargerId: this.selectedCharger.userId,
            chargerName: this.selectedCharger.name,
            // charger: this.selectedCharger

            // 所属区域
            areaId: area1?.id,
            areaName: area1?.areaName,
            subAreaId: area2?.id,
            subAreaName: area2?.areaName,
            l3AreaId: area3?.id,
            l3AreaName: area3?.areaName,

            // 是否集团项目
            isGroup: values.isGroup,
            // 合同性质
            property: this.selectedProperty.value,
            // 联合体情况
            unionType: values.unionType,
            // 项目地点
            belongProvince: values.location[0],
            belongCity: values.location[1],
            district: values.location[2],
            // 国外
            isAbroad: values.isAbroad,
            // 总投资
            investment: values.investment * 10000,
            // 服务内容
            phase: phaseList.join(),

            // 工程概况
            scale: values.scale,
            scaleList: this.scaleList,
            receiptList: this.collectionList.map((item, index) => {
              return {
                ...item,
                code: String(index + 1),
                planReceipt: values.contractAmount * 10000 * item.ratio * 0.01,
              };
            }),
            attachments: this.fileList.map((item) => item.completePath).join(),
            relationContractId: this.selectedContract.id
              ? this.selectedContract.id
              : "",
            relationContractName: this.selectedContract.name
              ? this.selectedContract.name
              : "",
          };

          this.storageValue = params;
          this.commentVisible = true;
          this.saveLoading = true;
        }
      });
    },

    // 审批意见弹窗设置
    setCommentVisible(visible) {
      this.commentVisible = visible;
      this.saveLoading = false;
    },

    done(comment) {
      this.saveLoading = true;
      if (this.id) {
        edit({
          ...this.storageValue,
          id: this.id,
          comment,
        })
          .then((res) => {
            console.log("res", res);
            this.$close(this.$route.path);
          })
          .finally(() => {
            this.commentVisible = false;
            this.saveLoading = false;
          });
      } else {
        add({
          ...this.storageValue,
          comment,
        })
          .then((res) => {
            console.log("res", res);
            this.$close(this.$route.path);
          })
          .finally(() => {
            this.commentVisible = false;
            this.saveLoading = false;
          });
      }
    },

    saveDraft() {
      this.updateDraft({
        id: this.draftId ? this.draftId : undefined,
        title: this.draftTitle + " " + moment().format("YYYY-MM-DD"),
        type: this.draftType,
        content: JSON.stringify({
          values: this.form.getFieldsValue(),
          selectedUser: this.selectedUser,
          selectedInfo: this.selectedInfo,
          newDesignCode: this.newDesignCode,
          selectedProject: this.selectedProject,
          selectedCompany: this.selectedCompany,
          selectedCharger: this.selectedCharger,
          selectedProperty: this.selectedProperty,

          scaleList: this.scaleList,
          collectionList: this.collectionList,
          fileList: this.fileList,
        }),
      }).then(() => {
        this.draftVisible = false;
        this.draftTitle = "";
      });
    },

    onSelectDraft(draft) {
      if (draft.id && draft.content) {
        this.draftId = draft.id; // 暂存使用的草稿 id
        this.draftTitle =
          typeof draft.title === "string" ? draft.title.split(" ")[0] : "";

        const res = JSON.parse(draft.content);

        this.form.setFieldsValue({
          ...res.values,
        });
        this.selectedUser = res.selectedUser;
        this.selectedInfo = res.selectedInfo;
        this.newDesignCode = res.newDesignCode;
        this.selectedProject = res.selectedProject;
        this.selectedCompany = res.selectedCompany;
        this.selectedCharger = res.selectedCharger;
        this.selectedProperty = res.selectedProperty;
        this.scaleList = res.scaleList;
        this.collectionList = res.collectionList;
        this.fileList = res.fileList;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;

  .header {
    display: flex;
    align-items: center;
    padding: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid #f0f0f0;
    color: #1890ff;
    font-weight: bold;
    font-size: 14px;

    img {
      width: 14px;
      margin-right: 4px;
    }

    .extra {
      margin-left: auto;
    }
  }

  .tip {
    color: #999;
  }
}

.center {
  margin-top: 80px;
  margin-bottom: 80px;
}
</style>
