<template>
  <div>
    <div @click="visible = true">
      <slot />
    </div>

    <a-modal
      title="关联项目"
      :visible="visible"
      @cancel="cancel"
      :footer="null"
      width="800px"
    >
      <a-input-search
        placeholder="请输入关键词搜索"
        enter-button
        @search="onSearch"
      ></a-input-search>

      <Padding />

      <a-table
        bordered
        :data-source="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
        :rowClassName="() => 'clickable'"
        :customRow="
          (record) => {
            return {
              on: {
                click: () => {
                  onTableRowClick(record);
                },
              },
            };
          }
        "
      >
        <a-table-column title="设计编号" data-index="designCode" />
        <a-table-column title="项目名称" data-index="name" />
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import { fetchProject } from "@/api/contract-register";
export default {
  data() {
    return {
      visible: false,

      searchValue: "",

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
    };
  },

  watch: {
    visible(newValue) {
      if (newValue) {
        if (this.list.length === 0) {
          this.getList();
        }
      }
    },
  },

  methods: {
    getList() {
      this.loading = true;
      fetchProject({
        pageNum: this.current,
        pageSize: this.pageSize,
        keyWord: this.searchValue,
      })
        .then((res) => {
          if (res && Array.isArray(res.list)) {
            this.list = Object.freeze(res.list);
            this.total = res.totalSize;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.getList();
    },

    onSearch(value) {
      console.log("value", value);
      this.searchValue = value;
      this.current = 1;
      this.getList();
    },

    onTableRowClick(record) {
      this.$emit("change", record);
      this.visible = false;
    },

    cancel() {
      this.visible = false;
    },
  },
};
</script>